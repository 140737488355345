import { template as template_7f99aacd32744c95a31683e068a0f588 } from "@ember/template-compiler";
const FKLabel = template_7f99aacd32744c95a31683e068a0f588(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
