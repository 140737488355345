import { template as template_18af4689cf164cd39580493f7f3ee32a } from "@ember/template-compiler";
const FKText = template_18af4689cf164cd39580493f7f3ee32a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
